(function () {
    'use strict';
    // DO NOT EDIT THIS FILE, EDIT THE GULP TASK NGCONSTANT SETTINGS INSTEAD WHICH GENERATES THIS FILE
    angular
        .module('josdaviApp')
        .constant('VERSION', "1.0.6")
        .constant('DEBUG_INFO_ENABLED', false)
        .constant('BUILD_TIMESTAMP', 1666127107589)
;
})();
