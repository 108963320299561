(function () {
    'use strict';

    angular
        .module('josdaviApp')
        .controller('InfoAdminController', InfosAdminController);

    InfosAdminController.$inject = ['GLOBAL_CONSTANTS', "$window", 'DataUtils', '$http', '$scope', 'Notification'];

    function InfosAdminController(GLOBAL_CONSTANTS, $window, DataUtils, $http, $scope, Notification) {
        var vm = this;
        vm.isSaving = false;
        vm.datePickerOpenStatus = {};
        vm.dateFormat = GLOBAL_CONSTANTS.DATE_FORMAT;
        vm.openCalendar = openCalendar;
        vm.datePickerOpenStatus.dateDebut = false;
        vm.datePickerOpenStatus.dateFin = false;
        vm.reseaux = GLOBAL_CONSTANTS.RESEAUX;

        vm.send = send;
        vm.clear = clear;
        vm.hasError = hasError;
        vm.setFichier = setFichier;

        vm.infos = {
            "contacts": null,
            "contenu": null,
            "sender": null
        };


        function hasError(form, field) {
            return (form.$submitted || field.$touched) && field.$invalid;
        }

        function send() {
            vm.isSaving = true;
            $http.post("api/infos-administration", angular.copy(vm.infos)).then(function () {
                Notification.success("Les informations ont été envoyées avec succès");
                vm.isSaving = false;
                vm.clear();
            }, function () {
                Notification.error("L'envoie du message a échoué");
                vm.isSaving = false;
            });
        }

        function clear() {
            vm.infos = {
                "contacts": null,
                "sender": null,
                "contenu": null
            };
        }

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function setFichier($file, infos) {
            if ($file) {
                DataUtils.toBase64($file, function (base64Data) {
                    $scope.$apply(function () {
                        infos.fichier = base64Data;
                        infos.fichierContentType = $file.type;
                    });
                });
            }
        }
    }
})();
