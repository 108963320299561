(function () {
        'use strict';

        angular
            .module('josdaviApp')
            .controller('UserManagementDialogController', UserManagementDialogController);

        UserManagementDialogController.$inject = ['copy', 'GLOBAL_CONSTANTS', 'Etablissement', 'Restangular', 'vue', '$scope', '$q', 'DataUtils', '$stateParams', '$uibModalInstance', 'entity', 'User', 'JhiLanguageService'];

        function UserManagementDialogController(copy, GLOBAL_CONSTANTS, Etablissement, Restangular, vue, $scope, $q, DataUtils, $stateParams, $uibModalInstance, entity, User, JhiLanguageService) {
            var vm = this;

            vm.isVue = function () {
                return vue;
            };
            vm.copy = copy;
            vm.typeActeur = ["Assistant", "Responsable", "Autre"];
            vm.authorities = ['ROLE_USER', 'ROLE_ADMIN', 'ROLE_RESPONSABLE_INSCRIPTION', 'ROLE_RESPONSABLE', 'ROLE_RESPONSABLE_ASSISTANT', 'ROLE_RESPONSABLE_ETABLISSEMENT', 'ROLE_ASSISTANT'];
            vm.clear = clear;
            vm.languages = null;
            vm.save = save;
            vm.user = entity;
            vm.user.email = "b@kafyka.com";

            var userAll = {};
            vm.removePicture = removePicture;
            vm.loadEtablissements = loadEtablissements;

            vm.etablissementColumns = [{
                name: 'numero',
                title: 'Numéro'
            }, {
                name: 'nom',
                title: 'Nom'
            }, {
                name: 'type',
                title: 'Type'
            }];

            $scope.$watch("vm.typeActeur", function (typeActeur) {
                if (typeActeur !== undefined && typeActeur !== null) {
                    switch (typeActeur) {
                        case "ASSISTANT":
                            vm.user.authorities = ['ROLE_USER', 'ROLE_ASSISTANT'];
                            break;
                        case "RESPONSABLE":
                            vm.user.authorities = ['ROLE_USER', 'ROLE_RESPONSABLE_ETABLISSEMENT'];
                            break;
                        case "AUTRE":
                            vm.user.authorities = [];
                            break;
                    }
                }
            });


            $scope.$on("onLoadEtablissements", function () {
                vm.loadEtablissements();
            });

            $scope.$watch("vm.user.etablissementId", function (id) {
                if (id !== undefined && id !== null) {
                    vm.etablissement = _.find(vm.etablissements, {
                        'id': id
                    });
                }
            });

            function loadEtablissements() {
                vm.etablissements = Etablissement.query({
                    size: GLOBAL_CONSTANTS.MAX_ITEMS_PER_PAGE,
                    sort: 'nom,asc'
                });
            }


            //Pour une modification effectué par un assistant

            if (angular.isDefined(vm.user.userExtraDTO)) {
                if (vm.user.userExtraDTO.id != null) {
                    userAll = vm.user;
                    vm.user = {};
                    vm.loadEtablissements();
                    if (!vm.copy) {
                        vm.user.id = userAll.userDTO.id;
                        vm.user.email = userAll.userDTO.email;
                        vm.user.login = userAll.userDTO.login;

                    } else {
                        vm.user.id = null;
                        vm.user.password = null;
                        vm.user.login = null;
                    }
                    vm.user.firstName = userAll.userDTO.firstName;
                    vm.user.lastName = userAll.userDTO.lastName;
                    vm.user.activated = userAll.userDTO.activated;
                    vm.user.authorities = userAll.userDTO.authorities;
                    vm.user.code = userAll.userExtraDTO.code;
                    vm.user.telephone = userAll.userExtraDTO.telephone;
                    vm.user.typeCarte = userAll.userExtraDTO.typeCarte;
                    vm.user.numeroCarte = userAll.userExtraDTO.numeroCarte;
                    vm.user.photo = userAll.userExtraDTO.photo;
                    vm.user.photoContentType = userAll.userExtraDTO.photoContentType;
                    vm.user.etablissementId = userAll.userExtraDTO.etablissementId;
                    Restangular.all('etablissements').getList().then(function (etablissements) {
                        vm.etablissements = etablissements;
                        if (vm.user.etablissementId !== undefined && vm.user.etablissementId !== null) {
                            vm.etablissement = _.find(vm.etablissements, {
                                'id': vm.user.etablissementId
                            });
                        }
                    });
                }
            }

            JhiLanguageService.getAll().then(function (languages) {
                vm.languages = languages;
            });

            function clear() {
                $uibModalInstance.dismiss('cancel');
            }

            function onSaveSuccess(result) {
                vm.isSaving = false;
                $uibModalInstance.close(result);
            }

            function onSaveError() {
                vm.isSaving = false;
            }

            function save() {
                vm.isSaving = true;
                vm.user.email = vm.user.login + "@kafyka.com";
                if (angular.isDefined(userAll.userExtraDTO)) {
                    if (userAll.userExtraDTO.id != null) {
                        if (!vm.copy) {
                            userAll.userDTO.login = vm.user.login;
                            userAll.userDTO.firstName = vm.user.firstName;
                            userAll.userDTO.lastName = vm.user.lastName;
                            userAll.userDTO.email = vm.user.email;
                            userAll.userDTO.activated = vm.user.activated;
                            userAll.userDTO.authorities = vm.user.authorities;

                            userAll.userExtraDTO.code = vm.user.code;
                            userAll.userExtraDTO.telephone = vm.user.telephone;
                            userAll.userExtraDTO.typeCarte = vm.user.typeCarte;
                            userAll.userExtraDTO.numeroCarte = vm.user.numeroCarte;
                            userAll.userExtraDTO.photo = vm.user.photo;
                            userAll.userExtraDTO.photoContentType = vm.user.photoContentType;
                            userAll.userExtraDTO.etablissementId = vm.user.etablissementId;
                            User.update(userAll, onSaveSuccess, onSaveError);
                        } else {
                            User.save(vm.user, onSaveSuccess, onSaveError);
                        }
                    }
                } else if (vm.user.id != null) { //Pour un ajout à travers une entite existente
                    userAll.userDTO = vm.user;
                    userAll.userExtraDTO = null;
                    User.update(userAll, onSaveSuccess, onSaveError);
                } else {
                    User.save(vm.user, onSaveSuccess, onSaveError);
                }
            }

            vm.setPhoto = function ($file, userExtra) {
                if ($file && $file.$error === 'pattern') {
                    return;
                }
                if ($file) {
                    DataUtils.toBase64($file, function (base64Data) {
                        $scope.$apply(function () {
                            userExtra.photo = base64Data;
                            userExtra.photoContentType = $file.type;
                        });
                    });
                }
            };


            function removePicture() {
                vm.user.photo = null;
                vm.user.photoContentType = null;
                vm.noPicture = false;
            }

        }
    }

)();
